import { notification, constructCourseFormData } from '~/utils'

const defaultState = () => ({
  searchTerm: '',
  courses: [],
  totalCourses: 0,
  currentCourse: {
    info: null,
    packages: [],
    currentPackage: null,
    codeBatches: [],
    codes: [],
    lessons: [],
    assistants: [],
    students: [],
    questionsTemplates: [],
    currentPackageSubscriptions: [],
    currentPackageNoPurchase: [],
    currentPackageAbsent: [],
    totalStudents: 0,
    totalCodeBatches: 0,
    totalLessons: 0,
    totalPackages: 0,
    totalPackageSubs: 0,
    totalPackageAbsent: 0,
    totalPackageNoPurchase: 0,
    totalQuestionsTemplates: 0,
  },
  currentStudent: {
    info: null,
    walletTransactions: [],
    totalWalletTransactions: 0,
  },
  currentLoadingCourseId: null,
  currentLesson: {
    info: null,
    videos: [],
    assessments: [],
    totalAssessments: 0,
    followups: [],
    totalFollowups: 0,
    currentAssessment: {
      info: null,
      questions: [],
    },
  },
})
export const state = defaultState()

export const mutations = {
  SET_SEARCH_TERM(state, term) {
    state.searchTerm = term
  },
  RESET_COURSES_STATE(state) {
    state.courses = []
    state.currentCourse = {
      info: null,
      packages: [],
      currentPackage: null,
      codeBatches: [],
      codes: [],
      lessons: [],
      assistants: [],
      students: [],
      questionsTemplates: [],
      currentPackageSubscriptions: [],
      currentPackageNoPurchase: [],
      currentPackageAbsent: [],
      totalStudents: 0,
      totalCodeBatches: 0,
      totalLessons: 0,
      totalPackages: 0,
      totalPackageSubs: 0,
      totalPackageNoPurchase: 0,
      totalPackageAbsent: 0,
      totalQuestionsTemplates: 0,
    }
    state.currentLesson = {
      info: null,
      videos: [],
      assessments: [],
      totalAssessments: 0,
      followups: [],
      totalFollowups: 0,
      currentAssessment: {
        info: null,
        questions: [],
      },
    }
  },
  SAVE_CREATED_QUESTION(state, { oldId, question }) {
    const questionIndex = state.currentLesson.currentAssessment.info.questions.data.findIndex(
      (q) => q.id === oldId
    )

    if (questionIndex !== -1) {
      state.currentLesson.currentAssessment.info.questions.data[questionIndex] = question
      state.currentLesson.currentAssessment.info = {
        ...state.currentLesson.currentAssessment.info,
      }
    }
  },
  RESET_CURRENT_PACKAGE_SUBSCRIPTIONS(state) {
    state.currentCourse.currentPackageSubscriptions = []
  },
  RESET_CURRENT_PACKAGE_NO_PURCHASE(state) {
    state.currentCourse.currentPackageNoPurchase = []
  },
  RESET_CURRENT_PACKAGE_ABSENT(state) {
    state.currentCourse.currentPackageAbsent = []
  },
  RESET_CURRENT_PACKAGE_CODES(state) {
    state.currentCourse.currentPackageCodes = []
  },
  RESET_CURRENT_COURSE(state) {
    state.currentCourse = {
      info: null,
      packages: [],
      currentPackage: null,
      codeBatches: [],
      codes: [],
      lessons: [],
      assistants: [],
      students: [],
      questionsTemplates: [],
      currentPackageSubscriptions: [],
      currentPackageNoPurchase: [],
      currentPackageAbsent: [],
      totalStudents: 0,
      totalCodeBatches: 0,
      totalLessons: 0,
      totalPackages: 0,
      totalPackageSubs: 0,
      totalPackageNoPurchase: 0,
      totalPackageAbsent: 0,
      totalQuestionsTemplates: 0,
    }
  },
  RESET_CURRENT_LESSON(state) {
    state.currentLesson = {
      info: null,
      videos: [],
      assessments: [],
      totalAssessments: 0,
      followups: [],
      totalFollowups: 0,
      currentAssessment: {
        info: null,
        questions: [],
      },
    }
  },
  RESET_CURRENT_LOADING_COURSE(state) {
    state.currentLoadingCourseId = null
  },
  SET_CURRENT_LOADING_COURSE(state, courseId) {
    state.currentLoadingCourseId = courseId
  },
  SET_COURSES(state, courses) {
    state.courses = courses
  },
  SET_CURRENT_COURSE(state, course) {
    const { courseAssistants, ...courseInfo } = course
    state.currentCourse.info = courseInfo
    state.currentCourse.assistants =
      courseAssistants?.data.map((e) => {
        return { id: e.id, name: e.assistant.data.name }
      }) || []
  },
  SET_CURRENT_COURSE_CODE_BATCHES(state, codeBatches) {
    state.currentCourse.codeBatches = codeBatches
  },
  SET_CURRENT_COURSE_CODES(state, codes) {
    state.currentCourse.codes = codes
  },
  SET_CURRENT_COURSE_LESSONS(state, lessons) {
    state.currentCourse.lessons = lessons
  },
  SET_CURRENT_COURSE_CURRENT_LESSON(state, lesson) {
    state.currentLesson.info = lesson
  },
  SET_CURRENT_COURSE_CURRENT_LESSON_VIDEOS(state, videos) {
    state.currentLesson.videos = videos
  },
  SET_CURRENT_COURSE_CURRENT_LESSON_ASSESSMENTS(state, assessments) {
    state.currentLesson.assessments = assessments
  },
  SET_CURRENT_COURSE_CURRENT_LESSON_FOLLOWUPS(state, followups) {
    state.currentLesson.followups = followups
  },
  SET_CURRENT_COURSE_CURRENT_LESSON_CURRENT_ASSESSMENT(state, assessment) {
    const questionsSorted = assessment.questions.data.sort((a, b) => a.position - b.position)
    assessment.questions.data = questionsSorted
    state.currentLesson.currentAssessment.info = assessment
  },
  SET_CURRENT_ASSESSMENT_QUESTIONS(state, questions) {
    state.currentLesson.currentAssessment.questions = questions
  },
  UPDATE_CURRENT_ASSESSMENT_QUESTIONS(state, questions) {
    state.currentLesson.currentAssessment.info = {
      ...state.currentLesson.currentAssessment.info,
      questions: { data: [...questions] },
    }
  },
  UPDATE_CURRENT_ASSESSMENT_RANDOMIZATION(state, randomization_property) {
    Object.assign(state.currentLesson.currentAssessment.info, randomization_property)
  },
  RESET_CURRENT_ASSESSMENT(state) {
    state.currentLesson.currentAssessment = { info: null, questions: [] }
  },
  SET_CURRENT_COURSE_PACKAGES(state, packages) {
    state.currentCourse.packages = packages
  },
  SET_CURRENT_COURSE_CURRENT_PACKAGE(state, packagee) {
    state.currentCourse.currentPackage = packagee
  },
  SET_CURRENT_PACKAGE_SUBSCRIPTIONS(state, subscriptions) {
    state.currentCourse.currentPackageSubscriptions = subscriptions
  },
  SET_CURRENT_PACKAGE_NO_PURCHASE(state, noPurchase) {
    state.currentCourse.currentPackageNoPurchase = noPurchase
  },
  SET_CURRENT_PACKAGE_ABSENT(state, Absent) {
    state.currentCourse.currentPackageAbsent = Absent
  },
  SET_CURRENT_COURSE_STUDENTS(state, students) {
    state.currentCourse.students = students
  },
  SET_CURRENT_COURSE_STUDENT(state, student) {
    state.currentStudent.info = student
  },
  SET_CURRENT_STUDENT_WALLET_TRANSACTIONS(state, walletTransactions) {
    state.currentStudent.walletTransactions = walletTransactions
  },
  SET_CURRENT_COURSE_QUESTIONS_TEMPLATES(state, questionsTemplates) {
    state.currentCourse.questionsTemplates = questionsTemplates
  },
  SET_TOTAL_STUDENTS(state, total) {
    state.currentCourse.totalStudents = total
  },
  SET_TOTAL_WALLET_TRANSACTIONS(state, total) {
    state.currentStudent.totalWalletTransactions = total
  },
  SET_TOTAL_COURSES(state, total) {
    state.totalCourses = total
  },
  SET_TOTAL_CODE_BATCHES(state, total) {
    state.currentCourse.totalCodeBatches = total
  },
  SET_TOTAL_LESSONS(state, total) {
    state.currentCourse.totalLessons = total
  },
  SET_TOTAL_ASSESSMENTS(state, total) {
    state.currentLesson.totalAssessments = total
  },
  SET_TOTAL_PACKAGES(state, total) {
    state.currentCourse.totalPackages = total
  },
  SET_TOTAL_PACKGE_SUBS(state, total) {
    state.currentCourse.totalPackageSubs = total
  },
  SET_TOTAL_PACKAGE_NO_PURCHASE(state, total) {
    state.currentCourse.totalPackageNoPurchase = total
  },
  SET_TOTAL_PACKAGE_ABSENT(state, total) {
    state.currentCourse.totalPackageAbsent = total
  },
  SET_TOTAL_PACKAGE_CODES(state, total) {
    state.currentCourse.totalPackageCodes = total
  },
  SET_CURRENT_PACKAGE_CODES(state, subscriptions) {
    state.currentCourse.currentPackageCodes = subscriptions
  },
  SET_TOTAL_QUESTIONS_TEMPLATES(state, total) {
    state.currentCourse.totalQuestionsTemplates = total
  },
  SET_TOTAL_FOLLOWUPS(state, total) {
    state.currentLesson.totalFollowups = total
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  resetCoursesState({ commit }) {
    commit('RESET_COURSES_STATE')
  },
  resetCurrentPackageSubscriptions({ commit }) {
    commit('RESET_CURRENT_PACKAGE_SUBSCRIPTIONS')
  },
  resetCurrentPackageNoPurchase({ commit }) {
    commit('RESET_CURRENT_PACKAGE_NO_PURCHASE')
  },
  resetCurrentPackageCodes({ commit }) {
    commit('RESET_CURRENT_PACKAGE_CODES')
  },
  resetCurrentCourse({ commit }) {
    commit('RESET_CURRENT_COURSE')
  },
  resetCurrentLesson({ commit }) {
    commit('RESET_CURRENT_LESSON')
  },
  resetCurrentAssessment({ commit }) {
    commit('RESET_CURRENT_ASSESSMENT')
  },
  // COURSES
  async fetchCourses({ commit, dispatch, state }, { per_page, page, q }) {
    try {
      const res = await this.$axios.$get('/api/v1/dashboard/courses', {
        params: { per_page, page, q: state.searchTerm || q },
      })
      commit('SET_COURSES', res.data)
      commit('SET_TOTAL_COURSES', res.meta.pagination.total)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchCourse({ commit, dispatch }, courseId) {
    if (Number(courseId) === state?.currentCourse?.info?.id) {
      return
    }

    try {
      commit('SET_CURRENT_LOADING_COURSE', courseId)

      const res = await this.$axios.$get(`/api/v1/dashboard/courses/${courseId}`, {
        params: { include: 'courseAssistants' },
      })

      await commit('SET_CURRENT_COURSE', res.data)
      commit('RESET_CURRENT_LOADING_COURSE')
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async createCourse({ dispatch }, { courseInfo, per_page, page }) {
    try {
      const formData = constructCourseFormData(courseInfo)
      await this.$axios.$post('/api/v1/dashboard/courses', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch('fetchCourses', { per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.course_created')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async updateCourse({ dispatch }, { courseInfo, courseId, per_page, page }) {
    console.log(courseInfo)

    try {
      if (courseInfo.thumbnail) {
        const formData = constructCourseFormData(courseInfo)
        formData.append('_method', 'PUT')
        await this.$axios.$post(`/api/v1/dashboard/courses/${courseId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      } else {
        await this.$axios.$put(`/api/v1/dashboard/courses/${courseId}`, {
          ...courseInfo,
        })
      }
      dispatch('fetchCourses', { per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.course_updated')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async deleteCourse({ dispatch }, { courseId, per_page, page }) {
    try {
      await this.$axios.$delete(`/api/v1/dashboard/courses/${courseId}`)
      dispatch('fetchCourses', { per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.course_deleted')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async resyncCourseStudents({ dispatch }, { courseId, per_page, page }) {
    try {
      await this.$axios.$put(`/api/v1/dashboard/courses/${courseId}/resync`)
      dispatch('fetchCourses', { per_page, page })
      dispatch(
        'notifications/add',
        notification('pending', this.$i18n.t('notifications.courses.course_resynced')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },

  // STUDENTS
  async fetchCourseStudents({ commit, dispatch }, params) {
    try {
      const { courseId, ...queryParams } = params

      Object.keys(queryParams).forEach((k) => queryParams[k] == undefined && delete queryParams[k])

      const res = await this.$axios.$get(`/api/v1/dashboard/courses/${courseId}/students`, {
        params: queryParams,
      })
      commit('SET_CURRENT_COURSE_STUDENTS', res.data)
      commit('SET_TOTAL_STUDENTS', res.meta.pagination.total)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  // Wallet Transactions
  async fetchStudentWalletTransactions({ commit, dispatch }, params) {
    try {
      const { courseId, studentId, ...queryParams } = params

      Object.keys(queryParams).forEach((k) => queryParams[k] == undefined && delete queryParams[k])

      const res = await this.$axios.$get(
        `/api/v1/dashboard/courses/${courseId}/students/${studentId}/wallet-transactions`,
        { params: queryParams }
      )
      commit('SET_CURRENT_STUDENT_WALLET_TRANSACTIONS', res.data)
      commit('SET_TOTAL_WALLET_TRANSACTIONS', res.meta.pagination.total)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  // STUDENT
  async fetchCourseStudent({ commit, dispatch }, params) {
    try {
      const { courseId, studentId, ...queryParams } = params

      Object.keys(queryParams).forEach((k) => queryParams[k] == undefined && delete queryParams[k])

      const res = await this.$axios.$get(
        `/api/v1/dashboard/courses/${courseId}/students/${studentId}`,
        { params: queryParams }
      )
      commit('SET_CURRENT_COURSE_STUDENT', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async addStudentToCourse({ dispatch }, { studentInfo, courseId, per_page, page, q }) {
    try {
      await this.$axios.$post(`/api/v1/dashboard/courses/${courseId}/students`, {
        ...studentInfo,
      })
      dispatch('fetchCourseStudents', { courseId, per_page, page, q })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.student_added')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async updateCourseStudent({ dispatch }, { courseId, studentId, studentInfo, per_page, page, q }) {
    try {
      await this.$axios.$put(`/api/v1/dashboard/courses/${courseId}/students/${studentId}`, {
        ...studentInfo,
      })
      dispatch('fetchCourseStudents', { courseId, per_page, page, q })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.student_updated')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async removeStudentFromCourse({ dispatch }, { courseId, studentId, per_page, page, q }) {
    try {
      await this.$axios.$delete(`/api/v1/dashboard/courses/${courseId}/students/${studentId}`)

      dispatch('fetchCourseStudents', { courseId, per_page, page, q })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.student_deleted')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async uploadStudentsToCourse({ dispatch }, { courseId, studentsFile, per_page, page, q }) {
    const formData = new FormData()
    formData.append('students_list', studentsFile)
    try {
      await this.$axios.$post(`/api/v1/dashboard/courses/${courseId}/students/import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch('fetchCourseStudents', { courseId, per_page, page, q })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.students_added')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },

  // PACKAGES
  async fetchCoursePackages({ commit, dispatch }, { courseId, per_page, page, archivedOnly }) {
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/courses/${courseId}/packages`, {
        params: { per_page, page, archivedOnly },
      })
      commit('SET_CURRENT_COURSE_PACKAGES', res.data)
      commit('SET_TOTAL_PACKAGES', res.meta.pagination.total)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchCourseCurrentPackage({ commit, dispatch }, { courseId, packageId }) {
    try {
      const res = await this.$axios.$get(
        `/api/v1/dashboard/courses/${courseId}/packages/${packageId}`
      )
      commit('SET_CURRENT_COURSE_CURRENT_PACKAGE', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async addPackageToCourse({ dispatch }, { courseId, packageInfo, per_page, page }) {
    try {
      await this.$axios.$post(`/api/v1/dashboard/courses/${courseId}/packages`, {
        ...packageInfo,
      })

      dispatch('fetchCoursePackages', { courseId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.package_added')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async updaetCoursePackage({ dispatch }, { courseId, packageInfo, per_page, page }) {
    const { id, ...rest } = packageInfo
    try {
      await this.$axios.$put(`/api/v1/dashboard/courses/${courseId}/packages/${id}`, {
        ...rest,
      })
      dispatch('fetchCoursePackages', { courseId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.package_updated')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async archiveCoursePackage({ dispatch }, { courseId, packageId, per_page, page }) {
    try {
      await this.$axios.$patch(`/api/v1/dashboard/packages/${packageId}/archive`)
      dispatch('fetchCoursePackages', { courseId, per_page, page })

      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.package_archived')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async unarchiveCoursePackage({ dispatch }, { courseId, packageId, per_page, page }) {
    try {
      await this.$axios.$patch(`/api/v1/dashboard/packages/${packageId}/unarchive`)
      dispatch('fetchCoursePackages', { courseId, per_page, page })

      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.package_unarchived')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async deleteCoursePackage({ dispatch }, { courseId, packageInfo, per_page, page }) {
    const { id } = packageInfo
    try {
      await this.$axios.$delete(`/api/v1/dashboard/courses/${courseId}/packages/${id}`)
      dispatch('fetchCoursePackages', { courseId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.package_deleted')),
        { root: true }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },

  // PACKAGE SUBSCRIPTIONS
  async fetchPackageSubscriptions({ commit, dispatch }, { packageId, per_page, page, q }) {
    try {
      const params = q ? { per_page, page, q } : { per_page, page }
      const res = await this.$axios.$get(`/api/v1/dashboard/packages/${packageId}/subscriptions`, {
        params,
      })
      commit('SET_TOTAL_PACKGE_SUBS', res.meta.pagination.total)
      commit('SET_CURRENT_PACKAGE_SUBSCRIPTIONS', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async addSubscriptionToPackage({ dispatch }, { packageId, studentsIds }) {
    try {
      await this.$axios.$post(`/api/v1/dashboard/packages/${packageId}/subscriptions`, {
        students: studentsIds,
      })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.package_subscription_added')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async fetchPackageNoPurchase({ commit, dispatch }, { packageId, per_page, page, q }) {
    try {
      const params = q ? { per_page, page, q } : { per_page, page }
      const res = await this.$axios.$get(`/api/v1/dashboard/packages/${packageId}/no-purchase`, {
        params,
      })
      commit('SET_TOTAL_PACKAGE_NO_PURCHASE', res.meta.pagination.total)
      commit('SET_CURRENT_PACKAGE_NO_PURCHASE', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchPackageAbsent({ commit, dispatch }, { packageId, per_page, page, q }) {
    try {
      const params = q ? { per_page, page, q } : { per_page, page }
      const res = await this.$axios.$get(`/api/v1/dashboard/packages/${packageId}/absent`, {
        params,
      })
      commit('SET_TOTAL_PACKAGE_ABSENT', res.meta.pagination.total)
      commit('SET_CURRENT_PACKAGE_ABSENT', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },

  addCodeToPackage({ dispatch }, { packageId, count, batch_name }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.$axios.$post(`/api/v1/dashboard/packages/${packageId}/codes`, {
          count,
          batch_name,
        })
        resolve(response)
        dispatch(
          'notifications/add',
          notification('success', this.$i18n.t('notifications.courses.package_codes_added')),
          {
            root: true,
          }
        )
      } catch (error) {
        if (error?.response?.status === 401) {
          return
        }
        reject(error)
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    })
  },

  async archiveCodeFromPackage({ dispatch }, { packageId, code }) {
    try {
      await this.$axios.$patch(`/api/v1/dashboard/packages/${packageId}/codes/${code.id}/archive`)
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.package_codes_archived')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },

  exportCodes({}, { packageId, limit }) {
    const limited = limit ? `?limit=${limit}` : ''

    window.location.href = `${this.$axios.defaults.baseURL}/api/v1/dashboard/packages/${packageId}/codes/export${limited}`
  },

  async updateCode({ dispatch }, { packageId, code }) {
    try {
      await this.$axios.$patch(`/api/v1/dashboard/packages/${packageId}/codes/${code.id}`)
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.package_codes_updated')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },

  // PACKAGE CODES
  async fetchPackageCodes({ commit, dispatch }, { packageId, per_page, page, q }) {
    try {
      const params = q ? { per_page, page, q } : { per_page, page }
      const res = await this.$axios.$get(`/api/v1/dashboard/packages/${packageId}/codes`, {
        params,
      })
      commit('SET_TOTAL_PACKAGE_CODES', res.meta.pagination.total)
      commit('SET_CURRENT_PACKAGE_CODES', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  // Code Search
  async searchForCode({ commit, dispatch }, filter) {
    const params = {}
    if (filter.pin_code) {
      params.pin_code = filter.pin_code
    }
    if (filter.serial_number) {
      params.serial_number = filter.serial_number
    }
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/codes/search`, { params })

      commit('SET_CURRENT_COURSE_CODES', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  // Code Batches
  async fetchCourseCodeBatches({ commit, dispatch }, { courseId, per_page, page }) {
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/courses/${courseId}/code-batches`, {
        params: { per_page, page, include: 'course,creator' },
      })

      commit('SET_CURRENT_COURSE_CODE_BATCHES', res.data)
      commit('SET_TOTAL_CODE_BATCHES', res.meta.pagination.total)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async addCodeBatchToCourse({ dispatch }, { codeBatchInfo, courseId, per_page, page }) {
    try {
      await this.$axios.$post(`/api/v1/dashboard/courses/${courseId}/code-batches`, codeBatchInfo)

      dispatch('fetchCourseCodeBatches', { courseId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.code_batch_adding')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async updateCourseCodeBatch({ dispatch }, { codeBatchInfo, courseId, per_page, page }) {
    try {
      const { id, ...info } = codeBatchInfo
      await this.$axios.$put(`/api/v1/dashboard/courses/${courseId}/code-batches/${id}`, info)
      dispatch('fetchCourseCodeBatches', { courseId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.code_batch_updated')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  // LESSONS
  async fetchCourseLessons({ commit, dispatch }, { courseId, per_page, page, q }) {
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/courses/${courseId}/lessons`, {
        params: { per_page, page, q, include: 'resources' },
      })

      commit('SET_CURRENT_COURSE_LESSONS', res.data)
      commit('SET_TOTAL_LESSONS', res.meta.pagination.total)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchCourseCurrentLesson({ commit, dispatch }, { courseId, lessonId }) {
    try {
      const res = await this.$axios.$get(
        `/api/v1/dashboard/courses/${courseId}/lessons/${lessonId}`,
        {
          params: { include: 'resources' },
        }
      )

      commit('SET_CURRENT_COURSE_CURRENT_LESSON', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async addLessonToCourse({ dispatch }, { lessonInfo, courseId, per_page, page }) {
    try {
      const lesson = lessonInfo
      lesson.resources = lesson.resources.map((file) => file.uuid).filter((uuid) => uuid)
      await this.$axios.$post(`/api/v1/dashboard/courses/${courseId}/lessons`, {
        ...lesson,
        params: { include: 'resources' },
      })

      dispatch('fetchCourseLessons', { courseId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.lesson_added')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async updateLessonCourse({ dispatch }, { lessonInfo, courseId, per_page, page }) {
    try {
      const lesson = lessonInfo
      lesson.resources = lesson.resources.map((file) => file.uuid).filter((uuid) => uuid)
      const { id, ...rest } = lesson
      await this.$axios.$put(`/api/v1/dashboard/courses/${courseId}/lessons/${id}`, {
        ...rest,
        params: { include: 'resources' },
      })
      dispatch('fetchCourseLessons', { courseId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.lesson_updated')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async removeLessonFromCourse({ dispatch }, { courseId, lessonId, per_page, page }) {
    try {
      await this.$axios.$delete(`/api/v1/dashboard/courses/${courseId}/lessons/${lessonId}`)

      dispatch('fetchCourseLessons', { courseId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.lesson_deleted')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },

  // LESSON VIDEOS
  async fetchLessonVideos({ commit, dispatch }, lessonId) {
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/lessons/${lessonId}/videos`)

      commit('SET_CURRENT_COURSE_CURRENT_LESSON_VIDEOS', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async addVideoToLesson({ dispatch }, { lessonId, videoInfo }) {
    try {
      await this.$axios.$post(`/api/v1/dashboard/lessons/${lessonId}/videos`, {
        ...videoInfo,
      })

      dispatch('fetchLessonVideos', lessonId)
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.video_added')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async updateVideoLesson({ dispatch }, { lessonId, videoInfo }) {
    const { id, ...rest } = videoInfo
    try {
      await this.$axios.$put(`/api/v1/dashboard/lessons/${lessonId}/videos/${id}`, {
        ...rest,
      })

      dispatch('fetchLessonVideos', lessonId)
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.video_updated')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async removeVideoFromLesson({ dispatch }, { lessonId, videoId }) {
    try {
      const res = await this.$axios.$delete(
        `/api/v1/dashboard/lessons/${lessonId}/videos/${videoId}`
      )

      dispatch('fetchLessonVideos', lessonId)
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.video_deleted')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },

  // LESSON ASSESSMENTS
  async fetchLessonAssessments({ commit, dispatch }, { lessonId, per_page, page }) {
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/lessons/${lessonId}/assessments`, {
        params: { per_page, page },
      })

      commit('SET_CURRENT_COURSE_CURRENT_LESSON_ASSESSMENTS', res.data)
      commit('SET_TOTAL_ASSESSMENTS', res.meta.pagination.total)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchAssessment({ commit, dispatch }, { lessonId, assessmentId }) {
    try {
      const res = await this.$axios.$get(
        `/api/v1/dashboard/lessons/${lessonId}/assessments/${assessmentId}?include=questions`
      )

      commit('SET_CURRENT_COURSE_CURRENT_LESSON_CURRENT_ASSESSMENT', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },

  async addAssessmentToLesson({ dispatch }, { assessmentInfo, lessonId, per_page, page }) {
    try {
      await this.$axios.$post(`/api/v1/dashboard/lessons/${lessonId}/assessments`, {
        ...assessmentInfo,
      })

      dispatch('fetchLessonAssessments', { lessonId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.assessment_added')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async updateAssessmentInLesson({ dispatch }, { assessmentInfo, lessonId, per_page, page }) {
    const { id, ...rest } = assessmentInfo
    try {
      await this.$axios.$put(`/api/v1/dashboard/lessons/${lessonId}/assessments/${id}`, {
        ...rest,
        open_at: rest.open_at || null,
        close_at: rest.close_at || null,
      })

      dispatch('fetchLessonAssessments', { lessonId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.assessment_updated')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async removeAssessmentFromLesson({ dispatch }, { lessonId, assessmentId, per_page, page }) {
    try {
      await this.$axios.$delete(`/api/v1/dashboard/lessons/${lessonId}/assessments/${assessmentId}`)

      dispatch('fetchLessonAssessments', { lessonId, per_page, page })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.assessment_deleted')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },

  // LESSON FOLLOWUPS
  async fetchLessonFollowups({ commit, dispatch }, { courseId, lessonId, per_page, page, q }) {
    try {
      const params = q ? { per_page, page, q } : { per_page, page }
      const res = await this.$axios.$get(
        `/api/v1/dashboard/courses/${courseId}/lessons/${lessonId}/followup?include=courseStudents.student`,
        {
          params,
        }
      )
      commit('SET_CURRENT_COURSE_CURRENT_LESSON_FOLLOWUPS', res.data)
      commit('SET_TOTAL_FOLLOWUPS', res.meta.pagination.total)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  // ASSESSMENT QUESTIONS
  async fetchAssessmentQuestions({ commit, dispatch }, assessmentId) {
    try {
      const res = await this.$axios.$get(`/api/v1/dashboard/lessons/${assessmentId}/assessments`)

      commit('SET_CURRENT_ASSESSMENT_QUESTIONS', res.data)
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) {
      } else {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },

  async addQuestionToAssessment({ dispatch, commit }, { questionInfo, assessmentId, lessonId }) {
    try {
      const { id, options, ...qInfo } = questionInfo

      // if image is a URL not a file, remove it from the object
      // otherwise, remove the image_question_template_id to prevent using the original image
      if (typeof qInfo.image === 'string') {
        delete qInfo.image
        qInfo.image_url = qInfo.unsigned_image || qInfo.image_url
      } else {
        delete qInfo.image_url
      }

      const formData = new FormData()

      for (const [key, value] of Object.entries(qInfo)) {
        if (value) {
          if (typeof value === 'boolean') {
            formData.append(key, value ? '1' : '0')
          } else {
            formData.append(key, value)
          }
        }
      }
      if (options && options.length) {
        options.forEach((option, i) => {
          formData.append(`options[${i}][is_correct]`, Number(option.is_correct))
          formData.append(`options[${i}][choice]`, option.choice)

          if (typeof option.image === 'string') {
            formData.append(`options[${i}][image_url]`, option.unsigned_image || option.image_url)
          }
          if (option.image && typeof option.image !== 'string') {
            formData.append(`options[${i}][image]`, option.image)
          }
        })
      }

      const res = await this.$axios.$post(
        `/api/v1/dashboard/assessments/${assessmentId}/questions`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      commit('SAVE_CREATED_QUESTION', {
        oldId: questionInfo.id,
        question: {
          ...res.data,
        },
      })

      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.question_added')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async updateQuestionToAssessment({ dispatch }, { questionInfo, assessmentId, lessonId }) {
    const { options } = questionInfo
    try {
      const formData = new FormData()
      if (questionInfo.image && typeof questionInfo.image !== 'string') {
        formData.append('image', questionInfo.image)
      }
      formData.append('title', questionInfo.title)
      if (questionInfo.body) {
        formData.append('body', questionInfo.body)
      }
      formData.append('position', questionInfo.position)
      formData.append('type', questionInfo.type)
      formData.append('grade', questionInfo.grade)

      if (typeof questionInfo.randomize_options === 'boolean') {
        formData.append('randomize_options', questionInfo.randomize_options ? '1' : '0')
      }
      if (options && options.length) {
        options.forEach((option, i) => {
          if (option.id) {
            formData.append(`options[${i}][id]`, option.id)
          }
          formData.append(`options[${i}][is_correct]`, Number(option.is_correct))
          formData.append(`options[${i}][choice]`, option.choice)

          if (typeof option.image === 'string') {
            formData.append(`options[${i}][image_url]`, option.unsigned_image)
          }
          console.log(option.image && typeof option.image !== 'string')
          if (option.image && typeof option.image !== 'string') {
            formData.append(`options[${i}][image]`, option.image)
          }
        })
      }
      formData.append('_method', 'PUT')
      const res = await this.$axios.$post(
        `/api/v1/dashboard/assessments/${assessmentId}/questions/${questionInfo.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      const questionId = res?.data.id

      // UPDATE OPTIONS
      // if (questionInfo.options) {
      //   await Promise.all(
      //     questionInfo.options.map((option) => {
      //       const formData = new FormData()
      //       if (option.image && typeof option.image !== 'string') {
      //         formData.append('image', option.image)
      //       }
      //       if (option.is_correct === true) {
      //         formData.append('is_correct', option.is_correct)
      //       }
      //       formData.append('choice', option.choice)
      //       formData.append('_method', 'PUT')
      //       return this.$axios.$post(
      //         `/api/v1/dashboard/questions/${questionId}/questionOptions/${option.id}`,
      //         formData,
      //         {
      //           headers: {
      //             'Content-Type': 'multipart/form-data',
      //           },
      //         }
      //       )
      //     })
      //   )
      // }
      // dispatch('setQuestionId', { / })
      // dispatch('fetchAssessment', { lessonId, assessmentId })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.question_updated')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async orderQuestionsOfAssessment({ dispatch }, { assessmentId, lessonId, questionsIds }) {
    try {
      await this.$axios.$post(`/api/v1/dashboard/assessments/${assessmentId}/questions/order`, {
        question: questionsIds,
      })
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async removeQuestionFromAssessment({ dispatch }, { assessmentId, questionId, lessonId }) {
    try {
      await this.$axios.$delete(
        `/api/v1/dashboard/assessments/${assessmentId}/questions/${questionId}`
      )

      dispatch('fetchAssessment', { lessonId, assessmentId })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.question_deleted')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },

  // QUESTIONS TEMPLATE
  async addQuestionTemplate({ dispatch }, { questionTemplateInfo, courseId, per_page, page }) {
    const { options, lessons, tags } = questionTemplateInfo

    const formData = new FormData()
    if (questionTemplateInfo.image && typeof questionTemplateInfo.image !== 'string') {
      formData.append('image', questionTemplateInfo.image)
    }
    formData.append('title', questionTemplateInfo.title)
    if (questionTemplateInfo.body) {
      formData.append('body', questionTemplateInfo.body)
    }
    if (questionTemplateInfo.answer) {
      formData.append('answer', questionTemplateInfo.answer)
    }
    if (questionTemplateInfo.answer_file) {
      formData.append('answer_file', questionTemplateInfo.answer_file)
    }
    formData.append('visibility', questionTemplateInfo.visibility)
    formData.append('level', questionTemplateInfo.level)
    if (lessons.data && lessons.data.length) {
      lessons.data.forEach((lesson, i) => {
        formData.append(`lessons[${i}]`, lesson.id)
      })
    }
    if (tags && tags.length) {
      tags.forEach((tag, i) => {
        formData.append(`tags[${i}]`, tag)
      })
    }

    formData.append('type', questionTemplateInfo.type)

    if (typeof questionTemplateInfo.randomize_options === 'boolean') {
      formData.append('randomize_options', questionTemplateInfo.randomize_options ? '1' : '0')
    }

    if (
      options.data &&
      options.data.length &&
      ['single_answer', 'multiple_answer'].includes(questionTemplateInfo.type)
    ) {
      options.data.forEach((option, i) => {
        if (option.id) {
          formData.append(`options[${i}][id]`, option.id)
        }
        formData.append(`options[${i}][is_correct]`, Number(option.is_correct))
        formData.append(`options[${i}][choice]`, option.choice)

        if (typeof option.image === 'string') {
          formData.append(`options[${i}][image_url]`, option.unsigned_image)
        }
        if (option.image && typeof option.image !== 'string') {
          formData.append(`options[${i}][image]`, option.image)
        }
      })
    }

    try {
      await this.$axios.$post(`/api/v1/dashboard/courses/${courseId}/questionTemplates`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.question_template_added')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
  async updateQuestionTemplate({ dispatch }, { questionTemplateInfo, courseId, per_page, page }) {
    const { id, ...rest } = questionTemplateInfo

    const { options, lessons, tags } = questionTemplateInfo

    const formData = new FormData()
    if (questionTemplateInfo.image && typeof questionTemplateInfo.image !== 'string') {
      formData.append('image', questionTemplateInfo.image)
    }
    formData.append('title', questionTemplateInfo.title)
    if (questionTemplateInfo.body) {
      formData.append('body', questionTemplateInfo.body)
    }
    if (questionTemplateInfo.answer) {
      formData.append('answer', questionTemplateInfo.answer)
    }
    if (questionTemplateInfo.answer_file) {
      formData.append('answer_file', questionTemplateInfo.answer_file)
    }
    formData.append('visibility', questionTemplateInfo.visibility)
    formData.append('level', questionTemplateInfo.level)
    if (lessons.data && lessons.data.length) {
      lessons.data.forEach((lesson, i) => {
        formData.append(`lessons[${i}]`, lesson.id)
      })
    }
    if (tags && tags.length) {
      tags.forEach((tag, i) => {
        formData.append(`tags[${i}]`, tag)
      })
    }

    formData.append('type', questionTemplateInfo.type)

    if (typeof questionTemplateInfo.randomize_options === 'boolean') {
      formData.append('randomize_options', questionTemplateInfo.randomize_options ? '1' : '0')
    }

    if (
      options.data &&
      options.data.length &&
      ['single_answer', 'multiple_answer'].includes(questionTemplateInfo.type)
    ) {
      options.data.forEach((option, i) => {
        if (option.id) {
          formData.append(`options[${i}][id]`, option.id)
        }
        formData.append(`options[${i}][is_correct]`, Number(option.is_correct))
        formData.append(`options[${i}][choice]`, option.choice)

        if (typeof option.image === 'string') {
          formData.append(`options[${i}][image_url]`, option.unsigned_image)
        }
        if (option.image && typeof option.image !== 'string') {
          formData.append(`options[${i}][image]`, option.image)
        }
      })
    }

    formData.append('_method', 'PUT')

    try {
      await this.$axios.$post(
        `/api/v1/dashboard/courses/${courseId}/questionTemplates/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.courses.question_template_updated')),
        {
          root: true,
        }
      )
    } catch (error) {
      if (error?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', error?.response), {
        root: true,
      })
    }
  },
}
