/* eslint-disable */
console.log('setting zoho US');

// zoho salesiq brand: UA
 window.$zoho=window.$zoho || {};$zoho.salesiq = $zoho.salesiq ||
 {widgetcode: "siq73088b0ed5e065fe8dd533583dbb42e4bb12d4560ae7ed1f1cf98f24e6f4dc4a", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);

 window.$zoho.salesiq.ready = function () {
   window.$zoho.salesiq.chat.logo("https://new.unipuka.app/meta/unipuka.png");
 }

